import MapboxLanguage from '@mapbox/mapbox-gl-language';
import { useControl } from 'react-map-gl';

// @example Nest this in a react-map-gl Map comp for it to work
// @implements react-map-gl implements map.addControl in this manner
// @return null per react-map-gl example
function MapLanguage() {
    const searchParams = new URLSearchParams(window.location.search || document.location.search);

    const langParam = searchParams.get("lang") || searchParams.get("locale");

    if (!langParam) return null;

    // https://docs.mapbox.com/data/tilesets/reference/mapbox-streets-v8/#name-text--name_lang-code-text
    const supportedLangs = {
        'fr': 'fr',
        'fr_ca': 'fr',
        'de': 'de',
        'es': 'es',
        'zh': 'zh-Hans',
        'zh-cn': 'zh-Hans',
        'zh_cn': 'zh-Hans',
        'zh-tw': 'zh-Hant',
        'zh-hk': 'zh-Hant',

    };

    for (const lang in supportedLangs) {
        if (langParam === lang) {
            useControl(() => new MapboxLanguage({ defaultLanguage: supportedLangs[lang] }));
            break;
        }
    }

    return null;
}

export default MapLanguage;
