import React, {
	useEffect,
	useRef,
	useState
} from 'react';

import { Map } from 'react-map-gl';
import MapLanguage from 'components/common/MapLanguage';
import MapsHelper from 'utils/maps';
import StopMarkers from 'components/Card/Map/StopMarkers';
import UserMarker from 'components/Card/Map/UserMarker';
import WalkingPath from 'components/Card/Map/WalkingPath';
import config from 'config';

function CardMap({
	card,
	screen,
	appLocation,
	params,
	showCardScreen,
	setGoogleMapsRouting // Share routing with the parent
}) {

	const userPosition = MapsHelper.getUserPosition(appLocation, screen);

	const stopMarkerPositions = card ? MapsHelper.getCardStopMarkerData(card, screen) : [];

	const mapCenter = (stopMarkerPositions.length > 0) ? stopMarkerPositions[0] : userPosition;

	// console.log("CardMap", appLocation, userPosition, stopMarkerPositions, mapCenter);
	if (!mapCenter) return null;

	const [state, setState] = useState({
		mapViewport: {
			width: 375,
			height: 350,
		}
	});

	const [viewState, setViewState] = useState({
		latitude: mapCenter.latitude,
		longitude: mapCenter.longitude,
		zoom: 14,
	})

	const mapRef = useRef();

	useEffect(() => {
		positionMapBounds();
	}, [showCardScreen]);

	function setUserPosition() {
		navigator.geolocation.getCurrentPosition((position) => {
			setState({
				...state,
				userPosition: {
					latitude: position.coords.latitude,
					longitude: position.coords.longitude
				}
			})
		});
	}

	function positionMapBounds() {

		if (!userPosition || stopMarkerPositions.length === 0) return null;

		const userCoord = [userPosition.longitude, userPosition.latitude];
		const markerCoords = stopMarkerPositions.map((marker) => (
			[marker.longitude, marker.latitude]
		));

		const boundSet = [userCoord, ...markerCoords];

		const mapBounds = boundSet.length > 2 ? MapsHelper.getMapboxBounds(boundSet) : boundSet;

		if (mapRef?.current) mapRef.current.fitBounds(mapBounds, { padding: { top: 100, bottom: 100, left: 50, right: 50, } });
		// setViewState(newViewState); // This isnt needed because onMove automatically updates viewState
	}

	const {
		ui
	} = params;
	const mapStyle = ui === 'dark' ? config.mapboxDarkStyle : config.mapboxLightStyle;

	return (
		<div className="CardMap">
			<Map
				ref={mapRef}
				style={{
					width: '100%',
					height: state.mapViewport.height
				}}
				onMove={evt => setViewState(evt.viewState)}
				// onLoad={onMapLoad}
				latitude={viewState.latitude}
				longitude={viewState.longitude}
				zoom={viewState.zoom}
				// initialViewState={viewState}
				// pitchWithRotate={false}
				// dragRotate={false}
				mapStyle={mapStyle}
				// onViewportChange={(viewport) => this.setState({ viewport })}
				mapboxAccessToken={config.mapboxToken}
			>
				<StopMarkers stopMarkerPositions={stopMarkerPositions} />
				<MapLanguage />
				{userPosition ? <UserMarker latitude={userPosition.latitude} longitude={userPosition.longitude} /> : null}
				{stopMarkerPositions.length > 0
					? (
						<WalkingPath
							setGoogleMapsRouting={setGoogleMapsRouting}
							startingPosition={{ longitude: userPosition.longitude, latitude: userPosition.latitude }}
							endPosition={{ longitude: stopMarkerPositions[0].longitude, latitude: stopMarkerPositions[0].latitude }}
						/>
					) : null}
			</Map>
		</div>
	);
}

export default CardMap
